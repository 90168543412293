<template>
  <div
    v-if="this.$route.matched.length > 2"
    class="animated fadeIn"
  >
    <router-view />
  </div>
  <div
    v-else
    class="animated fadeIn"
  >
    <b-tabs
      v-model="category"
      card
    >
      <b-tab
        id="category-activity"
        name="category"
        title="Активность"
        lazy
      >
        <route-report-activity />
      </b-tab>
      <b-tab
        id="category-tariffs-deviation"
        name="category"
        title="Отклонение фактических тарифов от базовых"
        lazy
      >
        <route-tariff-difference-main />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import RouteReportActivity from './ReportBlocks/RouteReportActivity/RouteReportActivity';
import RouteTariffDifferenceMain from './ReportBlocks/RouteTariffDifference/RouteTariffDifferenceMain';

export default {
  name: 'ReportsRoutes',
  components: {
    RouteTariffDifferenceMain,
    RouteReportActivity,
  },
  data: function() {
    return {
      category: 0,
    };
  },
};
</script>

<style lang="scss">
  .auction-filter-tabs {
    margin: -1px;

    &__item {
    }
    &__input {
      display: none;

      &:checked {

        ~ .auction-filter-tabs__display {
          color: #23282c;
          background-color: #fff;
          border-color: #c8ced3 #c8ced3 #fff;
        }
      }
    }
    &__display {
      background-color: transparent;
      margin-bottom: 0;
    }
  }

  .g-chart-container {
    position: relative;

    &__title {
      position: absolute;
      z-index: 1;
      top: 12px;
      left: 10%;
      width: 80%;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
    }
    &__form-group {
      position: absolute;
      z-index: 1;
      top: 68px;
      left: 10%;
      width: 80%;
    }
  }
</style>
